import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import Layout from '../layout';
import { H15 } from '../Texts';
import PrimaryLink from '../Buttons/PrimaryLink';

const SuccessMessageAgroActive = () => {
  const intl = useIntl();

  return (
    <Layout>
      <div className="flex items-center">
        <div className="px-4 py-6 lg:pt-16 lg:pl-32 lg:w-7/12 mb-16 lg:flex-shrink-0">
          <div className='lg:hidden flex flex-row items-center mb-2'>
            <img src='/images/agroactiva.png' width='220px' height='67px' alt='agroactiva' className='mr-2'/>
            <img src='/images/agro_imago.png' alt='agroactiva' className='mr-2' width='26px' height='19px'/>
          </div>
          <H15 className="mb-3">
            {intl.formatMessage({ id: 'getInTouchSuccess.heading' })}
          </H15>
          <p className="mb-6">
            {intl.formatMessage({ id: 'getInTouchSuccess.text1' })}
          </p>
          <PrimaryLink
            text={intl.formatMessage({
              id: 'getInTouchSuccess.backToHome.text',
            })}
            to={intl.formatMessage({ id: 'getInTouchSuccess.backToHome.url' })}
          />
        </div>
        <div className="hidden lg:flex">
          <img src='/images/AA_Logo2022Big.png' alt="contact" />
        </div>
      </div>
    </Layout>
  );
};

export default SuccessMessageAgroActive;
